import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"

const playerAttributes = () => {
  return (
    <Layout>
      <SEO
        title="Player Attributes in PES 2021"
        description="Here is every Player Attribute available in PES 2021."
      />
      <div className="container">
        <h1>Player Attributes in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Player Attributes</li>
        </ul>
        <p>
          Here you can find the official Konami description for each player
          stat. This is what sets a player apart, along with their unique set of{" "}
          <Link to="/player-skills/">Skills</Link>.
        </p>
        <p>
          Some stats were renamed in PES 2020 and some were introduced in last
          year's version, such as "Tight Possession" or "Aggression".
        </p>

        <p>
          <AnchorLink href="#inspire" offset="70">
            Inspire
          </AnchorLink>{" "}
          is something new which appeared first in PES 2020. You can also find
          the explanation and its possibilities at the bottom of this list.
        </p>
      </div>
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#playerattributeslist" offset="70">
                Player Attributes - The Complete List
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#inspire" offset="70">
                Inspire
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="playerattributeslist">Player Attributes - The Complete List</h2>
        <h3 className="bullet">Offensive Awareness</h3>
        <p>
          Indicates how quickly a player can respond to the ball when attacking.
        </p>
        <h3 className="bullet">Ball Control</h3>
        <p>
          Indicates how adept a player is at controlling the ball in general.
          Affects trapping and feints.
        </p>
        <h3 className="bullet">Dribbling</h3>
        <p>
          Indicates how adept a player is at maintaining control of the ball
          while dribbling at speed.
        </p>
        <h3 className="bullet">Tight Possession</h3>
        <p>
          Indicates how skilled a player is at performing turns while dribbling
          at low speed.
        </p>
        <h3 className="bullet">Low Pass</h3>
        <p>
          Indicates how accurate a player is when playing low passes along the
          ground.
        </p>
        <h3 className="bullet">Lofted Pass</h3>
        <p>Indicates the accuracy of the player's lofted passes.</p>
        <h3 className="bullet">Finishing</h3>
        <p>Indicates the player's shooting accuracy.</p>
        <h3 className="bullet">Set Piece Taking</h3>
        <p>
          Indicates a player's accuracy from set pieces including free kicks and
          penalties.
        </p>
        <h3 className="bullet">Curl</h3>
        <p>Indicates how much curl a player can put on the ball.</p>
        <h3 className="bullet">Header</h3>
        <p>Indicates the accuracy of the player's headers.</p>
        <h3 className="bullet">Defensive Awareness</h3>
        <p>
          Indicates how quickly a player can respond to the ball when defending.
        </p>
        <h3 className="bullet">Interceptions</h3>
        <p>
          Indicates how proficient the player is at winning the ball against an
          opponent.
        </p>
        <h3 className="bullet">Aggression</h3>
        <p>
          Indicates how aggressively the player will attempt to steal the ball
          during challenges.
        </p>
        <h3 className="bullet">Kicking Power</h3>
        <p>
          Indicates how much power a player can generate when kicking the ball.
        </p>
        <h3 className="bullet">Speed</h3>
        <p>Indicates how quick the player is when off the ball.</p>
        <h3 className="bullet">Acceleration</h3>
        <p>
          Indicates how quickly the player is able to reach his top running
          speed.
        </p>
        <h3 className="bullet">Balance</h3>
        <p>
          The higher the value, the better the player is at avoiding tackles and
          maintaining his balance when coming into contact with another player.
        </p>
        <h3 className="bullet">Physical Contact</h3>
        <p>
          The higher the value, the better the player is at winning challenges
          and not losing his balance when coming into contact with another
          player.
        </p>
        <h3 className="bullet">Jump</h3>
        <p>Indicates how high a player can jump.</p>
        <h3 className="bullet">Stamina</h3>
        <p>Indicates a player's level of fitness and endurance.</p>
        <h3 className="bullet">GK Awareness</h3>
        <p>
          Indicates how quickly a player can respond to the ball when playing in
          goal.
        </p>
        <h3 className="bullet">GK Catching</h3>
        <p>
          Indicates the goalkeeper's ability to catch the ball. A higher value
          means that the keeper can catch more powerful shots.
        </p>
        <h3 className="bullet">GK Clearing</h3>
        <p>
          Indicates the goalkeeper's ability to knock the ball away to safe
          areas.
        </p>
        <h3 className="bullet">GK Reflexes</h3>
        <p>Indicates the goalkeeper's ability to make quick reaction saves.</p>
        <h3 className="bullet">GK Reach</h3>
        <p>
          Indicates the goalkeeper's coverage of the goal and the size of the
          area in which he can make saves.
        </p>
        <h3 className="bullet">Weak Foot Usage</h3>
        <p>
          Indicates how often a player will use his weaker foot. The maximum
          value is 4.
        </p>
        <h3 className="bullet">Weak Foot Accuracy</h3>
        <p>
          Indicates how accurate a player is with his weaker foot. The maximum
          value is 4.
        </p>
        <h3 className="bullet">Form</h3>
        <p>
          Indicates a player's ability to maintain form over time. The maximum
          value is 8.
        </p>
        <p>
          <Link to="/player-form-condition-rating/">
            This is an important stat which I explain in more detail here
          </Link>
          .
        </p>
        <h3 className="bullet">Injury Resistance</h3>
        <p>
          Indicates a player's level of resistance to injury. The maximum value
          is 3.
        </p>
        <h2 id="inspire">Inspire</h2>
        <p>
          Players may have the Inspire characteristic based on his abilities.
          When on the ball, such players inspire teammates to behave in a way
          that complements their playstyle, meaning that this affects the
          players around the player that currently has the ball.
        </p>
        <p>
          There are 3 different Inspire possibilities, and a player can have up
          to 2 stars for each one.
        </p>
        <h3 className="bullet">Dribble on the Break</h3>
        <p>
          Available for players skilled at through-dribbling. Teammates will
          react as if the player is dribbling towards the goal.
        </p>
        <h3 className="bullet">Low Pass</h3>
        <p>
          Available for players skilled at low passing. Teammates will move as
          if the player is making a low pass.
        </p>
        <h3 className="bullet">Lofted Pass</h3>
        <p>
          Available for players skilled at lofted passing. Teammates will move
          as if the player is making a low pass.
        </p>
      </div>
    </Layout>
  )
}

export default playerAttributes
